/* global.css */
:root {
  --primary-color: #EB5F2C;
  --secondary-color: #1a1a1a;
  --text-dark: #2c3e50;
  --text-muted: #7f8c8d;
  --font-primary: 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
}

body {
  padding-top: 56px;
  font-family: var(--font-primary);
  color: var(--text-dark);
}

h1, h2, h3, h4 {
  color: var(--text-dark);
  font-weight: 700;
}

.text-primary {
  color: var(--primary-color) !important;
}

.bg-primary {
  background-color: var(--primary-color) !important;
}
  /* Texto escondido visualmente (acessível) */
  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border-width: 0;
  }

  /* Responsividade para a seção */
  @media (max-width: 768px) {
    #sobre > div {
      flex-direction: column;
    }
    
    #sobre > div > div:first-child {
      width: 100%;
      max-width: 400px;
      margin-bottom: 2rem;
    }
  }

  /* Layout dividido - Estilos reutilizáveis */
.split-section {
  padding: 5rem 0;
  background-color: #f8f8f8;
}

.split-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 5%;
  gap: 3rem;
}

.split-image {
  flex: 1 1 300px;
  min-width: 250px;
}

.split-image img {
  width: 100%;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
}

.split-content {
  flex: 1 1 500px;
}

.split-title {
  font-size: clamp(1.5rem, 4vw, 2rem);
  font-weight: 700;
  line-height: 1.3;
  margin-bottom: 1.5rem;
  color: #1a1a1a;
}

.split-highlight {
  color: #D84315;
}

.split-divider {
  width: 80px;
  height: 2px;
  background: #D84315;
  margin: 1.5rem 0;
}

.split-text {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 1.5rem;
  color: #333;
}

.split-list {
  margin: 1.5rem 0;
  padding-left: 1.5rem;
  list-style-type: none;
}

.split-list li {
  margin-bottom: 0.8rem;
  font-size: 1.1rem;
}

/* Responsividade */
@media (max-width: 768px) {
  .split-container {
    flex-direction: column;
  }
  
  .split-image {
    width: 100%;
    max-width: 400px;
    margin-bottom: 2rem;
  }
}